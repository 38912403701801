import React, { useMemo } from 'react';

import {
  getPluginComponentExtensions,
  GetPluginExtensionsOptions,
  usePluginComponents as usePluginComponentsOriginal,
} from '@grafana/runtime';
import { Card, Icon, Tooltip, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { Investigation, ReducedAnalysis } from 'grafana-ml-common/types';

import { InvestigationTagList } from './InvestigationTagList';

interface AnalysisResultsProps {
  investigations: Investigation[];
  checks: string[] | undefined;
  onlyInteresting: boolean;
}

// `usePluginComponents()` is only available in Grafana>=11.1.0, so we have a fallback for older versions
const usePluginComponents =
  usePluginComponentsOriginal !== undefined ? usePluginComponentsOriginal : useExtensionComponents;

export function AnalysisResults({ investigations, checks, onlyInteresting }: AnalysisResultsProps) {
  const styles = useStyles2(getStyles);
  const analyses = useMemo(
    () =>
      investigations.flatMap((i) =>
        i.analyses.items
          .filter(
            (a) =>
              (checks === undefined || checks.length === 0 || checks.includes(a.name)) &&
              (a.interesting || !onlyInteresting)
          )
          .map((a) => ({ investigation: i, analysis: a }))
      ),
    [investigations, checks, onlyInteresting]
  );
  return (
    <div className={styles.analyses}>
      <ul>
        {analyses.map(({ investigation, analysis }) => (
          <li key={analysis.id}>
            <Analysis investigation={investigation} analysis={analysis} />
          </li>
        ))}
      </ul>
    </div>
  );
}

interface AnalysisProps {
  investigation: Investigation;
  analysis: ReducedAnalysis;
}

type ComponentProps = {
  context: {
    investigationId: string;
    analysisId: string;
    show: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
};

function Analysis({ investigation, analysis }: AnalysisProps) {
  const [showModal, setShowModal] = React.useState(false);
  const styles = useStyles2(getStyles);
  const interestingComponent = useMemo(() => {
    return analysis.interesting ? (
      <span className={styles.interestingIcon}>
        <Tooltip content="Interesting result">
          <Icon name="bolt" />
        </Tooltip>
      </span>
    ) : null;
  }, [analysis.interesting, styles.interestingIcon]);
  const context = {
    investigationId: investigation.id,
    analysisId: analysis.id,
    show: showModal,
    onOpen: () => setShowModal(true),
    onClose: () => setShowModal(false),
  };
  const { components } = usePluginComponents<ComponentProps>({
    extensionPointId: 'plugins/grafana-sift-panel/view-analysis',
  });

  return (
    <div>
      <Card onClick={() => setShowModal(true)} className={styles.card}>
        <Card.Heading>{analysis.name}</Card.Heading>
        <Card.Figure className={styles.figure}>{[interestingComponent]}</Card.Figure>
        <Card.Meta>{[investigation.name]}</Card.Meta>
        <Card.Tags>
          <InvestigationTagList inputs={investigation.inputs ?? []} />
        </Card.Tags>
      </Card>

      {showModal && components.map((Component, i) => <Component key={i} context={context} />)}
    </div>
  );
}

const getStyles = () => {
  return {
    analyses: css`
      height: 100%;
      overflow-y: auto;
    `,
    interestingIcon: css`
      stroke: #ff8833;
      fill: #ff8833;
    `,
    card: css`
      grid-template-columns: 40px 5fr 3fr;
    `,
    figure: css`
      display: flex;
      align-items: center;
      height: 100%;
    `,
  };
};

// Fallback hook for Grafana versions >10.2 <11.1
function useExtensionComponents<Props>({ extensionPointId }: GetPluginExtensionsOptions): {
  isLoading: boolean;
  components: Array<React.ComponentType<Props>>;
} {
  const { extensions } = getPluginComponentExtensions<Props>({
    extensionPointId,
  });

  return {
    components: extensions.map((extension) => extension.component),
    isLoading: false,
  };
}
