import React, { useMemo } from 'react';

import { TagList } from '@grafana/ui';

import { Input, Label, LabelInput, MatchType } from 'grafana-ml-common/types';

function formatType(type: MatchType | undefined) {
  switch (type) {
    case MatchType.Equal:
    case undefined:
      return '=';
    case MatchType.NotEqual:
      return '!=';
    case MatchType.Regexp:
      return '=~';
    case MatchType.NotRegexp:
      return '!~';
  }
}

function formatLabel({ name, type, value }: Label) {
  return `${name}${formatType(type)}${value}`;
}

interface Props {
  inputs: Input[];
}

export function InvestigationTagList({ inputs }: Props) {
  const labels = useMemo(
    () => inputs.filter((ip): ip is LabelInput => ip.type === 'label').map(({ label }) => formatLabel(label)) ?? [],
    [inputs]
  );
  return <TagList displayMax={3} tags={labels} />;
}
