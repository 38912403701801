import { TimeRange } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';

import { Investigation } from 'grafana-ml-common/types';

import { MultiLabel } from './types';

interface CheckConfig {
  name: string;
  title?: string;
}

interface ConfigItem {
  instances: CheckConfig[];
}

export async function getConfiguredChecks(): Promise<Record<string, ConfigItem>> {
  const response = await getBackendSrv().get('/api/plugins/grafana-ml-app/resources/sift/api/v1/config');
  return response.data.checks as Record<string, ConfigItem>;
}

interface GetInvestigationOptions {
  labels?: MultiLabel[];
  timeRange?: TimeRange;
}

export async function getInvestigations({ labels, timeRange }: GetInvestigationOptions): Promise<Investigation[]> {
  const params = new URLSearchParams();
  for (const { name, value } of labels ?? []) {
    for (const val of value) {
      params.append('label', `${name}=${val}`);
    }
  }
  if (timeRange !== undefined) {
    params.set('start', timeRange.from.toISOString());
    params.set('end', timeRange.to.toISOString());
  }
  const url = '/api/plugins/grafana-ml-app/resources/sift/api/v1/investigations?' + params;
  const response = await getBackendSrv().get(url, undefined, undefined, { showErrorAlert: false });
  return response.data as Investigation[];
}
