import React, { useMemo } from 'react';

import { Card, Icon, Tooltip, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { Investigation } from 'grafana-ml-common/types';

import { InvestigationTagList } from './InvestigationTagList';

interface InvestigationProps {
  investigations: Investigation[];
}

export function Investigations({ investigations }: InvestigationProps) {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.investigations}>
      <ul>
        {investigations.map((i) => (
          <li key={i.id}>
            <InvestigationCard key={i.id} investigation={i} />
          </li>
        ))}
      </ul>
    </div>
  );
}

interface InvestigationCardProps {
  investigation: Investigation;
}

function InvestigationCard({ investigation }: InvestigationCardProps) {
  const styles = useStyles2(getStyles);
  const interestingComponent = useMemo(() => {
    const interestingCount = Object.values(investigation.analyses.countsByStage).reduce(
      (acc, state) => acc + state.interesting,
      0
    );
    return interestingCount > 0 ? (
      <span className={styles.interestingIcon}>
        <Tooltip content="Investigation contains interesting results">
          <Icon name="bolt" />
        </Tooltip>
      </span>
    ) : null;
  }, [investigation.analyses.countsByStage, styles.interestingIcon]);

  return (
    <Card href={`/a/grafana-ml-app/investigations/${investigation.id}`} className={styles.card}>
      <Card.Heading>{investigation.name}</Card.Heading>
      <Card.Figure className={styles.figure}>{[interestingComponent]}</Card.Figure>
      <Card.Meta>{[investigation.created]}</Card.Meta>
      <Card.Tags>
        <InvestigationTagList inputs={investigation.inputs ?? []} />
      </Card.Tags>
    </Card>
  );
}

const getStyles = () => {
  return {
    investigations: css`
      height: 100%;
      overflow-y: auto;
    `,
    interestingIcon: css`
      stroke: #ff8833;
      fill: #ff8833;
    `,
    card: css`
      grid-template-columns: 40px 5fr 3fr;
    `,
    figure: css`
      display: flex;
      align-items: center;
      height: 100%;
    `,
  };
};
