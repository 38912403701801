import { PanelPlugin } from '@grafana/data';

import { getConfiguredChecks } from './api';
import { LabelEditor } from './components/LabelEditor';
import { SiftPanel } from './components/SiftPanel';
import { SiftOptions } from './types';

export const plugin = new PanelPlugin<SiftOptions>(SiftPanel)
  .setPanelOptions((builder) => {
    return builder
      .addRadio({
        name: 'Display mode',
        description: 'Choose what to display in the panel.',
        path: 'displayMode',
        settings: {
          options: [
            {
              title: 'Investigations',
              value: 'investigations',
              label: 'Investigations',
            },
            {
              title: 'Analysis results',
              value: 'analysisResults',
              label: 'Check results',
            },
          ],
        },
        defaultValue: 'investigations',
      })
      .addCustomEditor({
        id: 'labels',
        name: 'Labels',
        path: 'labels',
        description: 'Filter investigations using provided labels. Template variables are supported.',
        editor: LabelEditor,
      })
      .addMultiSelect({
        name: 'Checks',
        path: 'checks',
        description: 'Checks to show.',
        showIf: (config) => config.displayMode === 'analysisResults',
        settings: {
          options: [],
          getOptions: async () => {
            const checks = await getConfiguredChecks();
            return Object.values(checks).flatMap((c) =>
              c.instances.map((i) => ({ label: i.title ?? i.name, value: i.title ?? i.name }))
            );
          },
          isClearable: true,
          allowCustomValue: false,
        },
      })
      .addBooleanSwitch({
        name: 'Only interesting results',
        path: 'onlyInteresting',
        description: 'Only display analyses with interesting results.',
        showIf: (config) => config.displayMode === 'analysisResults',
        defaultValue: true,
      });
  })
  .setDataSupport({ annotations: false, alertStates: false });
