import React, { useEffect, useState } from 'react';

import { PanelProps } from '@grafana/data';
import { getTemplateSrv } from '@grafana/runtime';
import { Alert, Button } from '@grafana/ui';

import { Investigation } from 'grafana-ml-common/types';

import { getInvestigations } from '../api';
import { MultiLabel, SiftOptions } from '../types';
import { interpolateLabel } from '../utils/interpolateLabel';
import { AnalysisResults } from './AnalysisResultsPanel';
import { EmptyPanel } from './EmptyPanel';
import { Investigations } from './InvestigationsPanel';

export function SiftPanel({ options, timeRange }: PanelProps<SiftOptions>) {
  const templateSrv = getTemplateSrv();
  const [investigations, setInvestigations] = useState<Investigation[] | undefined>(undefined);
  const [refreshKey, setRefreshKey] = useState(0);
  const [error, setError] = useState<string | undefined>(undefined);

  const interpolatedLabels: MultiLabel[] = (options.labels ?? [])
    .map((label) => interpolateLabel(label, templateSrv))
    .filter((x): x is MultiLabel => x !== undefined);
  // We can't use `interpolatedLabels` in the `useEffect` dependencies array
  // below because the ID of the object changes on every render; stringify
  // it first to avoid infinite rerenders.
  const interpolatedLabelKey = JSON.stringify(interpolatedLabels);

  useEffect(() => {
    getInvestigations({ timeRange, labels: interpolatedLabels })
      .then((value) => {
        setInvestigations(value);
        setError(undefined);
      })
      .catch((error) => void setError(error.data.message));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange, interpolatedLabelKey, refreshKey]);

  if (error !== undefined) {
    return (
      <Alert title="Error fetching investigations" severity="error">
        <Button
          variant="secondary"
          onClick={() => {
            setError(undefined);
            setRefreshKey(refreshKey + 1);
          }}
        >
          Retry
        </Button>
      </Alert>
    );
  }

  if (investigations === undefined) {
    return <div>Loading...</div>;
  }

  if (investigations.length === 0) {
    return <EmptyPanel timeRange={timeRange} labels={interpolatedLabels} />;
  }

  switch (options.displayMode) {
    case 'investigations':
    case undefined:
      return <Investigations investigations={investigations} />;
    case 'analysisResults':
      return (
        <AnalysisResults
          investigations={investigations}
          checks={options.checks}
          onlyInteresting={options.onlyInteresting ?? true}
        />
      );
  }
}
