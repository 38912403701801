import React from 'react';

import { PanelOptionsEditorProps } from '@grafana/data';

import { Labels } from 'grafana-ml-common/components';
import { Label } from 'grafana-ml-common/types';

export function LabelEditor({ value, onChange }: PanelOptionsEditorProps<Label[]>) {
  return (
    <Labels
      onChange={onChange}
      values={value ?? []}
      validation={{ valid: true, messages: [] }}
      showErrors={false}
      allowNonEquality={false}
    />
  );
}
