import React from 'react';

import { SelectableValue } from '@grafana/data';
import { FieldValidationMessage, IconButton, InlineFieldRow, Input, Select } from '@grafana/ui';

import { css } from '@emotion/css';

import { MatchType } from '../types';

interface LabelFieldProps {
  name: string;
  value: string;
  type: MatchType;
  errorMessage: string;
  previewMessage: string;
  onChange: (name: string, value: string, type: MatchType) => void;
  onRemove: () => void;
  onStartChange?: () => void;
  onEndChange?: () => void;
  allowNonEquality?: boolean;
}

const matchTypes: Array<SelectableValue<MatchType>> = [
  { label: '=', value: MatchType.Equal },
  { label: '!=', value: MatchType.NotEqual },
  { label: '=~', value: MatchType.Regexp },
  { label: '!~', value: MatchType.NotRegexp },
];

function LabelField({
  name,
  value,
  type,
  errorMessage,
  previewMessage,
  onChange,
  onRemove,
  onStartChange,
  onEndChange,
  allowNonEquality,
}: LabelFieldProps): JSX.Element {
  const showMatchType = allowNonEquality ?? true;

  return (
    <InlineFieldRow className={styles.container}>
      <div className={styles.row}>
        <Input
          className={styles.labelSelect}
          placeholder="Label name"
          value={name}
          onKeyDown={() => {
            if (onStartChange !== undefined) {
              onStartChange();
            }
          }}
          onBlur={() => {
            if (onEndChange !== undefined) {
              onEndChange();
            }
          }}
          onChange={(option) =>
            option.currentTarget.value !== undefined && onChange(option.currentTarget.value, value, type)
          }
        />
        {showMatchType && (
          <Select
            options={matchTypes}
            value={type}
            width={20}
            onChange={(option) => option.value !== undefined && onChange(name, value, option.value)}
          />
        )}
        <Input
          className={styles.valueInput}
          placeholder="Label value"
          value={value}
          onChange={(event) =>
            event.currentTarget.value !== undefined && onChange(name, event.currentTarget.value, type)
          }
          onKeyDown={() => {
            if (onStartChange !== undefined) {
              onStartChange();
            }
          }}
          onBlur={() => {
            if (onEndChange !== undefined) {
              onEndChange();
            }
          }}
        />
        <IconButton name="trash-alt" aria-label="Remove label" onClick={() => onRemove()} tooltip="Remove label" />
      </div>
      {previewMessage !== '' ? (
        <FieldValidationMessage className={styles.previewMsg}>{previewMessage}</FieldValidationMessage>
      ) : null}
      {errorMessage !== '' ? (
        <FieldValidationMessage className={styles.validationMsg}>{errorMessage}</FieldValidationMessage>
      ) : null}
    </InlineFieldRow>
  );
}

export { LabelField };

const styles = {
  container: css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    width: 100%;
  `,
  labelSelect: css`
    flex-grow: 1;
  `,
  valueInput: css`
    flex-grow: 1;
    margin-right: 8px;
  `,
  validationMsg: css`
    flex-basis: 100%;
  `,
  previewMsg: css`
    background-color: rgb(255, 179, 87);
    color: rgb(0, 0, 0);
    flex-basis: 100%;
  `,
};
