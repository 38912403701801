import { CustomVariableModel, QueryVariableModel, TypedVariableModel } from '@grafana/data';
import { TemplateSrv, VariableInterpolation } from '@grafana/runtime';
import { VariableFormatID } from '@grafana/schema';

import { Label } from 'grafana-ml-common/types';

import { MultiLabel } from '../types';

export type MinimalQueryVariableModel = Pick<QueryVariableModel, 'name' | 'type' | 'allValue'>;
export type MinimalCustomVariableModel = Pick<CustomVariableModel, 'name' | 'type' | 'allValue'>;

// A minimal subset of TemplateSrv with the stuff we actually need, to make testing simpler.
export interface MinimalTemplateSrv {
  replace: TemplateSrv['replace'];
  getVariables: () => Array<TypedVariableModel | MinimalQueryVariableModel | MinimalCustomVariableModel>;
}

// Replace any template variables in the value of a label, so that e.g. `$cluster`
// is converted to an array containing all selected values of the 'cluster' template variable.
//
// If the first variable in the label allows `All` to be selected AND the interpolated value
// of the label is equal to the variable's configured `allValue`, this function returns
// `undefined` instead of a `MultiLabel`, because there is no need to filter on a variable
// set to 'All'.
//
// If the variable is a single valued variable, the value of the output `MultiLabel` will
// contain a single entry for the selected value. Otherwise it will contain all selected
// values.
export function interpolateLabel({ name, value }: Label, templateSrv: MinimalTemplateSrv): MultiLabel | undefined {
  // Use this array to keep track of which variables have actually been interpolated in this label.
  // We need this to compare the final value with the 'All' value of this the variable, since we
  // can just ignore this label if it was set to 'All'.
  // It's populated by `templateSrv.replace`.
  const interpolations: VariableInterpolation[] = [];
  // Use the 'JSON' variable format so that multi-values are converted to
  // JSON arrays, like `["dev", "prod"]`; then parse it again so we can convert that
  // into a real array, which is easier to work with later when calling Sift's API.
  const replaced = templateSrv.replace(value, undefined, VariableFormatID.JSON, interpolations);
  // Try and find the first variable that was used in the replacement, so that we can
  // check its `allValue` against the replaced value.
  // We only care about the first; if there were more than one then the final value is
  // unlikely to look like the `allValue`, so we don't need to worry about Alls.
  // (This is true because `interpolations.length` could only be > 1 if the label
  // looked something like `$cluster/$namespace`, which wouldn't look anything like
  // a useful `allValue`).
  //
  // If there were none then the next if statement will be ignored.
  const variable = templateSrv.getVariables().find((x) => x.name === interpolations[0]?.variableName);
  if (
    variable !== undefined &&
    (variable.type === 'query' || variable.type === 'custom') &&
    variable.allValue === replaced
  ) {
    return undefined;
  }
  // If JSON parsing fails it will be because the variable is single-valued, so this
  // will be our final value.
  let arrayValue = [replaced];
  try {
    // Try to parse the JSON, in case the value included a multi-value variable.
    const parsed = JSON.parse(replaced);
    if (Array.isArray(parsed)) {
      arrayValue = parsed;
    }
  } catch (_e) {
    // The value must not have been a multi-value variable so JSON parsing probably failed.
    // That's fine, we can just leave arrayValue as-is.
  }
  return {
    name,
    value: arrayValue,
  };
}
